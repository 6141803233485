import * as React from "react";
import "./Home.css";
import NavBar from "../HomeComponents/navbar";
import Footer from "../HomeComponents/footer";
import { Container } from "react-bootstrap";
import puzzle from "../../assets/man-holding-puzzle.svg";
import logo_1 from "../../assets/logo-1.png";
import logo_2 from "../../assets/logo-2.png";
import logo_3 from "../../assets/logo-3.png";
import logo_4 from "../../assets/logo-4.png";
import logo_5 from "../../assets/logo-5.png";
import logo_6 from "../../assets/logo-6.png";
import logo_7 from "../../assets/logo-7.png";
import logo_8 from "../../assets/logo-8.png";
import logo_9 from "../../assets/logo-9.png";
import logo_10 from "../../assets/logo-10.png";
import atydy from "../../assets/atyudimy.svg";
import Clarity from "../Products/Clarity";
import YudimyGames from "../Products/YudimyGames";
import Precision from "../Products/Precision";
import Socials from "../HomeComponents/socials";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { useHistory } from "react-router-dom";

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  smalltablet: {
    breakpoint: { max: 800, min: 500 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 2,
  },
};
const YudimyHome = () => {
  const { push } = useHistory();
  React.useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 500,
      offset: 100,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <NavBar home={true} />
      <Container className="ydyhome-container" fluid={true}>
        <div className="ydyhome-hero">
          <div className="ydyhome-hero-sub">
            <div data-aos="zoom-in">
              <h1>Make Informed Decisions Backed by Data</h1>
              <p>
                <div>
                  Yudimy is a Behavioural profiling algorithm that blends
                  psychology and machine learning to help people and
                  organisations make intuitive decisions backed by data.
                </div>
                <div>
                  The Yudimy behavioural profiling algorithm prepares and
                  matches personality traits and talent with career
                  opportunities they are best suited for, minimizes the errors
                  that come with a career- talent mismatch for professionals &
                  organisations, relieves the awkwardness of establishing
                  meaningful relationships, and appreciating individual
                  differences which leads to optimized human capital performance
                  for people, teams, and families.
                </div>
              </p>
              <button onClick={() => push("/contact")} data-aos="zoom-in-right">Request Demo</button>
            </div>
            <img
              className="ydyhome-man-image"
              src={puzzle}
              alt="man holding puzzle"
            />
          </div>
        </div>
        <div className="ydyhome-partners">
          <p>Companies that trust working with us</p>
          <Carousel
            responsive={responsive}
            containerClass=""
            focusOnSelect={true}
            swipeable={true}
            draggable={true}
            keyBoardControl={true}
            transitionDuration={500}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            removeArrowOnDeviceType={[
              "mobile",
              "smalltablet",
              "tablet",
              "desktop",
              "largeDesktop",
            ]}
          >
            <img src={logo_1} alt="partner logo" />
            <img src={logo_2} alt="partner logo" />
            <img src={logo_3} alt="partner logo" />
            <img src={logo_4} alt="partner logo" />
            <img src={logo_9} alt="partner logo" />
            <img src={logo_10} alt="partner logo" />
            <img src={logo_8} alt="partner logo" />
            <img src={logo_5} alt="partner logo" />
            <img src={logo_6} alt="partner logo" />
            <img src={logo_7} alt="partner logo" />
          </Carousel>
          <hr />
        </div>
        <div className="ydyhome-atyudimy">
          <img
            src={atydy}
            alt="put the pieces together"
            data-aos="zoom-in-down"
          />
          <div>
            <h5 data-aos="fade-up">
              At Yudimy <br />
              We are fitting Square Pegs into Square Holes
            </h5>
            <p>
              <div data-aos="fade-up">
                Simplifying human behaviour, we make it easier for people,
                managers, and leaders to understand how people's talent and
                personality dynamics influence their life and work productivity.
              </div>

              <div data-aos="fade-up">
                Data mined provides actionable insights and recommendations to
                enable people, families, and teams to be happily productive by
                finding the right career opportunities and talent, as well as,
                make informed career educational, talent acquisition, and
                management decisions in less time and at over 90% accuracy.
              </div>
            </p>
            <button onClick={() => push("/contact")} data-aos="fade-up">
              Request Demo
            </button>
          </div>
        </div>
        <div className="ydyhome-whyyudimy">
          <h3 data-aos="fade-up">Why Yudimy</h3>
          <p data-aos="fade-up">
            Our goal is to build happily productive societies, from the smallest
            unit (family) to a larger Audience (Communities)
          </p>
          <div className="ydyhome-reasons">
            <div
              className="ydyhome-why"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
            >
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Accuracy & Objectivity</h6>
                <p>
                  Our tools provide objective and accurate data on human
                  behaviour.
                </p>
              </div>
            </div>
            <div
              className="ydyhome-why"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
            >
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Consumer Behaviour</h6>
                <p>
                  Our APIs understands consumers true motivations, drivers,
                  values & priorities.
                </p>
              </div>
            </div>
            <div
              className="ydyhome-why"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Data Mining</h6>
                <p>
                  Our behavioural data bank is a useful resource with insights
                  on people’s priorties.
                </p>
              </div>
            </div>
            <div
              className="ydyhome-why"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
            >
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Native Intelligence</h6>
                <p>
                  Our behavioural profiling tool understands African human
                  behaviour.
                </p>
              </div>
            </div>
            <div
              className="ydyhome-why"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
            >
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Optimized Performance</h6>
                <p>
                  We enable people make informed decisions that accelerate
                  performance 10x.
                </p>
              </div>
            </div>
            <div
              className="ydyhome-why"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
            >
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Personalization </h6>
                <p>
                  We prioritize people and their individual differences when
                  solving their problems.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ydyhome-ourprod">
          <h3 data-aos="fade-up">Our Products & Services</h3>
          <p data-aos="fade-up">
            In our Quest to achieving our goals we are constantly building super
            products validated with years of Research and User Centric Tests
          </p>
        </div>
        <Clarity />
        <YudimyGames />
        <Precision />
        <Socials />
      </Container>
      <Footer />
    </>
  );
};

export default YudimyHome;
