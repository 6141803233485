import * as React from "react";
import "./products.css";
import NavBar from "../HomeComponents/navbar";
import Footer from "../HomeComponents/footer";
import { Container } from "react-bootstrap";
import Clarity from "../Products/Clarity";
import YudimyGames from "../Products/YudimyGames";
import Precision from "../Products/Precision";

const YudimyProducts = () => {
  return (
    <>
      <NavBar products={true} />
      <Container className="ydyproducts-container" fluid={true}>
        <div className="ydyproducts-hero">
          <h1>Our Products</h1>
          <p>
            What started as a curious itch to understand the problem of talent
            mismatch in Sub-saharan Africa evolved to become an innovative
            engine that enables people and organizations to be happily
            productive.
          </p>
        </div>
        <Clarity />
        <YudimyGames />
        <Precision />
      </Container>
      <Footer />
    </>
  );
};

export default YudimyProducts;
