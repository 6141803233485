import * as React from "react";
import "./products.css";
import card from "../../assets/ydycard.svg";
import jumia from "../../assets/jumialogo.png";
import arrow from "../../assets/left_arrow.png";
import card1 from "../../assets/carddesign1.png";
import card2 from "../../assets/carddesign2.png";
import card3 from "../../assets/carddesign3.png";
import card4 from "../../assets/carddesign4.png";
import card5 from "../../assets/carddesign5.png";
import AOS from "aos";
import "aos/dist/aos.css";

const YudimyGames = () => {
  const [state, setState] = React.useState({
    count: 0,
  });
  React.useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1500,
      offset: 300,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  const { count } = state;
  const nextImage = () => {
    if (count === 5) {
      return;
    } else {
      setState({
        ...state,
        count: count + 1,
      });
    }
  };
  const prevImage = () => {
    if (count === 0) {
      return;
    } else {
      setState({
        ...state,
        count: count - 1,
      });
    }
  };
  // console.log(count);
  return (
    <>
      <div className="ydyproducts-card">
        <div
          className="ydyproducts-card-imgsec"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="ydyproducts-card-bg"></div>
          <img
            className={
              count === 0
                ? "ydyproducts-card-imgsec-img"
                : "ydyproducts-card-imgsec-img2"
            }
            src={
              count === 0
                ? card
                : count === 1
                ? card1
                : count === 2
                ? card2
                : count === 3
                ? card3
                : count === 4
                ? card4
                : card5
            }
            alt="yudimy card game"
          />
        </div>
        <div className="ydyproducts-card-infosec">
          <div className="ydyproducts-card-ctrl">
            <span onClick={prevImage}>&lt;</span>
            <span onClick={nextImage}>&gt;</span>
          </div>
          <h4
            className="ydyproducts-card-ttl"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            You Discover Me Games
          </h4>
          <p
            className="ydyproducts-card-txt"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            A personality guessing game that eliminates the akwardness that
            comes with establishing meaningful human connection
            <br />
            The game covers these 5 areas:
          </p>
          <div className="ydyproducts-card-list">
            <ul>
              <li data-aos="fade-up" data-aos-duration="3000">
                Childhood Memoirs
              </li>
              <li data-aos="fade-up" data-aos-duration="3000">
                Work Style
              </li>
              <li data-aos="fade-up" data-aos-duration="3000">
                LifeStyle
              </li>
            </ul>
            <ul>
              <li data-aos="fade-up" data-aos-duration="3000">
                Friendship
              </li>
              <li data-aos="fade-up" data-aos-duration="3000">
                Values
              </li>
            </ul>
          </div>
          <a href="https://flutterwave.com/store/yudimy/0ist9qqska7h" target="_blank">
            <div
              className="ydyproducts-card-buy"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              Buy now
              {/* <img
                src={jumia}
                alt="jumia logo"
                data-aos="fade-up"
                data-aos-duration="3000"
              /> */}
            </div>
          </a>
        </div>
      </div>
      <a
        className="disable-link-underline"
        href="https://flutterwave.com/store/yudimy/0ist9qqska7h"
        target="_blank"
      >
        <div className="ydyproducts-clarity-mobile">
          <h6
            className="ydycard-ttl"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            YDM CARD GAME
          </h6>
          <p
            className="ydyproducts-clarity-mobile-txt1"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            A personality guessing game eliminates the akwardness that comes
            with establishing meaning human connection using an interactive card
            game. The game covers these 5 areas:
          </p>
          <p
            className="ydyproducts-clarity-mobile-txt2"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            Buy now{" "}
            <img
              className="ydyproducts-clarity-mobile-arrow"
              data-aos="fade-up"
              data-aos-duration="3000"
              src={arrow}
              alt="left arrow"
            />
          </p>
        </div>
      </a>
    </>
  );
};

export default YudimyGames;
