import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import "../Home/Home.css";
import NavBar from "../HomeComponents/navbar";
import Footer from "../HomeComponents/footer";
import CallingIcon from "../../assets/Calling-icon.svg";
import MessageIcon from "../../assets/Message-icon.svg";
import LocationIcon from "../../assets/Location-icon.svg";
import twitterIcon from "../../assets/twitter-icon.svg";
import linkedinIcon from "../../assets/linkedin-icon.svg";
import facebookIcon from "../../assets/facebook-icon.svg";
import instagramIcon from "../../assets/instagram-icon.svg";
import contactImg from "../../assets/contact-img.svg";
import Socials from "../HomeComponents/socials";
import "./Contact.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const YudimyContact = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
    successMessage: "",
    isLoading: false,
  });
  const { name, email, message, successMessage, isLoading } = state;

  const onSubmit = () => {
    const subject = `Yudimy Contact Form`;
    const body = `Hello, I'm ${name}.\n${message}`;
    return window.open(
      `mailto:ask@yudimy.com?subject=${subject}&body=${body}`
    );
  };

  const validateForm = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      return setState({
        ...state,
        successMessage: "all fields are required",
      });
    }
    if (name && email && message) {
      onSubmit();
    }
  };

  const onChangeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const notify = (message: string) => {
    window.scrollTo(-0, -0);
    toast(message, { containerId: "B" });
  };
  return (
    <>
      <NavBar contact={true} />
      <Container className="main-container" fluid={true}>
        <div className="contact-container">
          <div className="contact-img">
            <img src={contactImg} className="contact-image" />
          </div>
          <div className="main-view">
            <Row className="main-row">
              <Col md={5} sm={12} className="info-aspect">
                <Row className="get-in-touch">
                  <h1>Get In Touch</h1>
                  <p>
                    Fill up the form and our team will get back to you in the
                    shortest time possible
                  </p>
                </Row>
                <Row>
                  <div className="company-info message-start">
                    <span>
                      <img src={MessageIcon} className="contact-icon" />
                    </span>
                    <a target="_blank" href="mailto:ask@yudimy.com">
                      ask@yudimy.com
                    </a>
                  </div>
                </Row>
                <Row>
                  <div className="company-info">
                    <span>
                      <img src={LocationIcon} className="contact-icon" />
                    </span>
                    <p>Ogudu, Lagos Nigeria</p>
                  </div>
                </Row>
                <Row>
                  <div className="company-info">
                    <span>
                      <img src={CallingIcon} className="contact-icon" />
                    </span>{" "}
                    <p>+234 (0) 817 610 0160</p>
                  </div>
                </Row>
                <Row>
                  <div className="social-icons">
                    <ul className="social-links">
                      <li>
                        <a
                          href="https://free.facebook.com/pages/category/Product-Service/109680753747119/?_rdc=1&_rdr"
                          target="_blank"
                        >
                          <img src={facebookIcon} className="icon-svg" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/askyudimy?s=08"
                          target="_blank"
                        >
                          <img src={twitterIcon} className="icon-svg" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/askyudimy/"
                          target="_blank"
                        >
                          <img src={instagramIcon} className="icon-svg" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/yudimy"
                          target="_blank"
                        >
                          <img src={linkedinIcon} className="icon-svg" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Col>
              <Col md={7} sm={12} className="form-aspect">
                <div className="contact-form">
                  <Row className="form-fields">
                    <div className="form-heading form-name">
                      <p>Your Name</p>
                    </div>
                    <input
                      type="text"
                      name="name"
                      placeholder="Please enter your name"
                      onChange={onChangeHandler}
                      value={name}
                      className="contact-input"
                    />
                  </Row>
                  <Row className="form-fields">
                    <div className="form-heading">
                      <p>Your Email</p>
                    </div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your mail"
                      onChange={onChangeHandler}
                      value={email}
                      className="contact-input"
                    />
                  </Row>
                  <Row className="form-fields">
                    <div className="form-heading">
                      <p>Your Message</p>
                    </div>
                    <textarea
                      className="text-area"
                      name="message"
                      onChange={onChangeHandler}
                      value={message}
                    ></textarea>
                  </Row>
                  {/* {successMessage && (
                    <Alert key={2} variant="success" className="success-mssg">
                      {successMessage}
                    </Alert>
                  )} */}
                  <div className="contact-button">
                    <button className="contact-btn" onClick={validateForm}>
                      {isLoading ? "Sending..." : "Send Message"}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="mobile-container">
          <div className="contact-mobile-view">
            <Row className="get-in-touch-mobile">
              <h1>Get In Touch</h1>
              <p>
                Fill up the form and our team will get back to you in the
                shortest time possible
              </p>
            </Row>
            <div className="contact-form-mobile">
              <Row className="form-fields-mobile">
                <div className="form-heading-mobile form-name">
                  <p>Your Name</p>
                </div>
                <input
                  type="text"
                  name="name"
                  onChange={onChangeHandler}
                  value={name}
                  className="contact-input"
                />

                <div className="form-heading-mobile">
                  <p>Your Email</p>
                </div>
                <input
                  type="email"
                  name="email"
                  onChange={onChangeHandler}
                  value={email}
                  className="contact-input"
                />

                <div className="form-heading-mobile">
                  <p>Your Message</p>
                </div>
                <textarea
                  className="text-area"
                  name="message"
                  onChange={onChangeHandler}
                  value={message}
                ></textarea>
              </Row>
              {/* {successMessage && (
                <Alert key={2} variant="success" className="success-mssg">
                  {successMessage}
                </Alert>
              )} */}
              <div className="contact-button">
                <button className="contact-btn" onClick={validateForm}>
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </div>

            <Row className="company-details-mobile">
              <div className="company-info-mobile message-mobile">
                <span>
                  <img src={MessageIcon} className="contact-icon-mobile " />
                </span>
                <p>ask@yudimy.com</p>
              </div>

              <div className="company-info-mobile">
                <span>
                  <img src={LocationIcon} className="contact-icon-mobile" />
                </span>
                <p>Ogudu, Lagos Nigeria</p>
              </div>

              <div className="company-info-mobile">
                <span>
                  <img src={CallingIcon} className="contact-icon" />
                </span>{" "}
                <p>+234 (0) 817 610 0160</p>
              </div>
            </Row>
          </div>
        </div>
      </Container>
      <Footer />
      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
    </>
  );
};

export default YudimyContact;
