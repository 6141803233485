import * as React from "react";
import { Container } from "react-bootstrap";
import "./games.css";
import "../HomeComponents/animate.css"
import NavBar from "../HomeComponents/navbar";
import Footer from "../HomeComponents/footer";
import "../Products/products.css";
import jumia from "../../assets/jumialogo.png";
import arrow from "../../assets/left_arrow.png";
import card from "../../assets/carddesign6.png";
import card1 from "../../assets/carddesign7.png";
import card2 from "../../assets/carddesign8.png";
import card3 from "../../assets/carddesign1.png";
import card4 from "../../assets/carddesign2.png";
import card5 from "../../assets/carddesign3.png";
import card6 from "../../assets/carddesign4.png";
import card7 from "../../assets/carddesign5.png";
import "../Home/Home.css";
import chat from "../../assets/chaticon.png";
import node from "../../assets/nodeicon.png";
import card_1 from "../../assets/card1.png";
import card_2 from "../../assets/card2.png";
import card_3 from "../../assets/card3.png";
import card_4 from "../../assets/card4.png";
import card_5 from "../../assets/card5.png";
import card_6 from "../../assets/card6.png";
import card_7 from "../../assets/card7.png";

const Games = () => {
  const [state, setState] = React.useState({
    count: 0,
  });
  const { count } = state;
  const nextImage = () => {
    if (count === 7) {
      return;
    } else {
      setState({
        ...state,
        count: count + 1,
      });
    }
  };
  const prevImage = () => {
    if (count === 0) {
      return;
    } else {
      setState({
        ...state,
        count: count - 1,
      });
    }
  };
  return (
    <>
      <NavBar games={true} />
      <Container className="ydygames-container" fluid={true}>
        <div className="ydygames-hero">
          <h1>You Discover Me Card Game</h1>
          <p>
            A personality guessing game that eliminates the akwardness that
            comes with establishing meaningful human connection.
          </p>
        </div>
        <div className="ydygames-mobile-card" >
          <img src={card} />
        </div>
        <div className="ydyproducts-card ydygames-cards">
          <div className="ydygames-cards-imgsec animated fadeInLeft">
            <img
              className={
                count === 0 || count === 1 || count === 2
                  ? "ydygames-cards-img animated fadeInLeft"
                  : "ydygames-cards-img2 animated fadeInLeft"
              }
              src={
                count === 0
                  ? card
                  : count === 1
                  ? card1
                  : count === 2
                  ? card2
                  : count === 3
                  ? card3
                  : count === 4
                  ? card4
                  : count === 5
                  ? card5
                  : count === 6
                  ? card6
                  : card7
              }
              alt="yudimy card game"
            />
          </div>
          <div className="ydyproducts-card-infosec ydygames-cards-infosec">
            <div className="ydyproducts-card-ctrl">
              <span onClick={prevImage}>&lt;</span>
              <span onClick={nextImage}>&gt;</span>
            </div>
            <h4 className="ydyproducts-card-ttl">
              Build healthy interpersonal relationships
            </h4>
            <p className="ydyproducts-card-txt">
              Kickstart quality conversations and create bonds with the You
              Discover Me Game Cards
            </p>
            <div className="ydygames-card-list">
              <ul>
                <li>Childhood Memoirs</li>
                <li>Work Style</li>
                <li>LifeStyle</li>
              </ul>
              <ul>
                <li>Friendship</li>
                <li>Values</li>
              </ul>
            </div>
            <div className="ydyproducts-card-buy">
               <span className="buy_now"><a href={"https://flutterwave.com/store/yudimy"} target="blank"> Buy Now</a></span>
            </div>
          </div>
        </div>
        <a className="disable-link-underline" href="" target="_blank">
          <div className="ydyproducts-clarity-mobile">
            <h6 className="ydycard-ttl">YDM CARD GAME</h6>
            <p className="ydyproducts-clarity-mobile-txt1">
              Kickstart quality conversations and create bonds with the You
              Discover Me Game Cards
            </p>
            <p className="ydyproducts-clarity-mobile-txt2">
              Shop on Jumia{" "}
              <img
                className="ydyproducts-clarity-mobile-arrow"
                src={arrow}
                alt="left arrow"
              />
            </p>
          </div>
        </a>
        <div className="ydyhome-whyyudimy ydygames-cutsacross">
          <h3>YDM Card Game cuts across all angles</h3>
          <div className="ydyhome-reasons">
            <div className="ydyhome-why">
              <div className="ydyhome-why-circle">
                <img src={node} alt="icon" />
              </div>
              <div className="ydyhome-why-txt">
                <h6>Networking</h6>
                <p>Build a network of happy people with deeper connections</p>
              </div>
            </div>
            <div className="ydyhome-why">
              <div className="ydyhome-why-circle">
                <img src={chat} alt="icon" />
              </div>
              <div className="ydyhome-why-txt">
                <h6>Personal Reflection</h6>
                <p>Retrace your steps and rediscover who you truly are</p>
              </div>
            </div>
            <div className="ydyhome-why">
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>First Dates</h6>
                <p>
                  Break that awkwardnes of first dates and kick off great
                  converstations
                </p>
              </div>
            </div>
            <div className="ydyhome-why">
              <div className="ydyhome-why-circle">
                <img src={node} alt="icon" />
              </div>
              <div className="ydyhome-why-txt">
                <h6>Intimacy</h6>
                <p>Brings you closer to your players</p>
              </div>
            </div>
            <div className="ydyhome-why">
              <div className="ydyhome-why-circle">
                <img src={chat} alt="icon" />
              </div>
              <div className="ydyhome-why-txt">
                <h6>Conflict Resolution</h6>
                <p>
                  Fixes rifts and beefs by helping you understand each other
                  better
                </p>
              </div>
            </div>
            <div className="ydyhome-why">
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Family Bonding</h6>
                <p>Rekindle and Build stronger family bonds</p>
              </div>
            </div>
            <div className="ydyhome-why">
              <div className="ydyhome-why-circle">
                <div className="ydyhome-why-box"></div>
              </div>
              <div className="ydyhome-why-txt">
                <h6>Ice Breaker</h6>
                <p>
                  Perfect ice breakers in small or large gatherings - Fit for
                  formal and informal ocassions
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ydygames-cards-sec">
          <div className="ydygames-cards-sec-1">
            <img
              className="ydygames-cards-sec-1-img"
              src={card_1}
              alt="yudimy card"
            />
            <img
              className="ydygames-cards-sec-1-img"
              src={card_2}
              alt="yudimy card"
            />
          </div>
          <div className="ydygames-cards-sec-2">
            <div className="ydygames-cards-sec-2-a">
              <img className="ydygames-cards-sec-2-a-img1" src={card_3} alt="yudimy card" />
              <div className="ydygames-cards-sec-2-b">
                <img
                  className="ydygames-cards-sec-2-b-img1"
                  src={card_4}
                  alt="yudimy card"
                />
                <img
                  className="ydygames-cards-sec-2-b-img2"
                  src={card_5}
                  alt="yudimy card"
                />
              </div>
            </div>
            <div className="ydygames-cards-sec-2-c">
              <img
                className="ydygames-cards-sec-2-c-img1"
                src={card_6}
                alt="yudimy card"
              />
              <img
                className="ydygames-cards-sec-2-c-img2"
                src={card_7}
                alt="yudimy card"
              />
            </div>
          </div>
        </div>
        <div className="ydygames-cards-sec-mobile">
              <img src={card_1} alt="yudimy card"/>
              <img src={card_2} alt="yudimy card"/>
              <img src={card_3} alt="yudimy card"/>
              <img src={card4} alt="yudimy card"/>
              <img src={card5} alt="yudimy card"/>
              <img src={card6} alt="yudimy card"/>
              <img src={card7} alt="yudimy card"/>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Games;
