import * as React from "react";
import "./navbar.css";
import "./animate.css";
import logo from "../../assets/yudimylogo.svg";
import purplelogo from "../../assets/yudimypurplelogo.svg";
import SideNav from "react-simple-sidenav";
import { Link } from "react-router-dom";

const YudimyNavBar = (props: any) => {
  const [state, setShowNav] = React.useState({
    showNav: false,
  });
  const { showNav } = state;
  const [darknav, setDarkNav] = React.useState(false);
  const uniqueKeygen = (): number => {
    return Math.floor(Math.random() * 100);
  };
  React.useEffect(() => {
    window.scrollTo(-0, -0);
    if (window.location.pathname === "/"){
      setDarkNav(true);
    } 
    else {
      setDarkNav(false);
    }
  }, []);

  return (
    <>
      <div className={darknav ? "ydynavbar ydynavbar-purple" : "ydynavbar ydynavbar-white"}>
        <div className="ydynavbar-mobile-container">
          <SideNav
            openFromRight={true}
            style={{
              background: showNav
                ? "rgba(0, 0, 0, 0.7)"
                : "inherit",
            }}
            navStyle={{ width: "70%", background: darknav ? "#000000" : "#f7f7f7" }}
            showNav={showNav}
            onHideNav={() => setShowNav({ ...state, showNav: true })}
            titleStyle={{
              background:
                "linear-gradient(306.97deg, #45108A 12.12%, #3D065F 41.04%, #200554 116.01%)",
              paddingLeft: 10,
              paddingBottom: 0,
              paddingTop: 0,
              height: 73,
            }}
            itemStyle={{
              background: darknav ? "#000000" : "#f7f7f7",
              paddingLeft: 25,
              paddingRight: 65,
            }}
            itemHoverStyle={{ backgroundColor: darknav ? "#000000" : "#f7f7f7" }}
            title={[
              <div
                key={uniqueKeygen()}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  background:
                    "linear-gradient(306.97deg, #45108A 12.12%, #3D065F 41.04%, #200554 116.01%)",
                  padding: "0px 4px 1px 8px",
                  color: "white",
                  fontSize: "4rem",
                  height: 73,
                }}
              >
                <span
                  className={
                    showNav
                      ? "close-navbar animated lightSpeedIn space"
                      : "close-navbar"
                  }
                  onClick={() =>
                    setShowNav({ ...state, showNav: !showNav ? true : false })
                  }
                >
                  &times;
                </span>
              </div>,
            ]}
            items={[
              <div
                className={
                  showNav
                    ? "ydynavbar-mobile-list animated fadeInLeft"
                    : "ydynavbar-mobile-list"
                }
              >
                <Link to="/" className="ydy-nav-item-link">
                  <div className={darknav ? "ydynavbar-mobile-list-item" : "ydynavbar-mobile-list-item-white"}>Home</div>
                </Link>
                {/* <Link to="/about" className="ydy-nav-item-link">
                  <div className={darknav ? "ydynavbar-mobile-list-item" : "ydynavbar-mobile-list-item-white"}>About Us</div>
                </Link> */}
                <Link to="/products" className="ydy-nav-item-link">
                  <div className={darknav ? "ydynavbar-mobile-list-item" : "ydynavbar-mobile-list-item-white"}>Products</div>
                </Link>
                <Link to="/games" className="ydy-nav-item-link">
                  <div className={darknav ? "ydynavbar-mobile-list-item" : "ydynavbar-mobile-list-item-white"}>Yudimy Games</div>
                </Link>
                <Link to="/contact" className="ydy-nav-item-link">
                  <div className={darknav ? "ydynavbar-mobile-list-item" : "ydynavbar-mobile-list-item-white"}>Contact Us</div>
                </Link>
              </div>,
            ]}
          />
          <div className="ydynavbar-mobile">
            <Link to="/">
              <img src={darknav ? logo : purplelogo} className="ydymobilelogo" alt="yudimy logo" />
            </Link>
            <div
              className="ydynavbar-hamburger"
              onClick={() =>
                setShowNav({ ...state, showNav: !showNav ? true : false })
              }
            >
              <div className={darknav ? "shortline" : "shortline-purple"}></div>
              <div className={darknav ? "line1" : "line1-purple"}></div>
              <div className={darknav ? "shortline" : "shortline-purple"}></div>
            </div>
          </div>
        </div>
        <div
          className={
            darknav
              ? "ydynavbar-desktop ydynavbar-desktop-purple"
              : "ydynavbar-desktop ydynavbar-desktop-white"
          }
        >
          <Link to="/">
            <img
              className="ydynavbar-logo"
              src={darknav ? logo : purplelogo}
              alt="yudimy logo"
            />
          </Link>
          <div className="ydynavbar-items">
            <Link to="/" className="ydy-nav-item-link">
              <span
                className={
                  props.home && darknav
                    ? "ydy-nav-item ydynavbar-items-list ydy-nav-selected"
                    : props.home && !darknav
                    ? "ydy-nav-item ydynavbar-items-list-purple ydy-nav-selected-purple"
                    : !props.home && darknav
                    ? "ydy-nav-item-purple"
                    : !props.home && !darknav
                    ? "ydy-nav-item-white"
                    : ""
                }
              >
                Home
              </span>
            </Link>
            {/* <Link to="/about" className="ydy-nav-item-link">
              <span
                className={
                  props.about && darknav
                    ? "ydy-nav-item ydynavbar-items-list ydy-nav-selected"
                    : props.about && !darknav
                    ? "ydy-nav-item ydynavbar-items-list-purple ydy-nav-selected-purple"
                    : !props.about && darknav
                    ? "ydy-nav-item-purple"
                    : !props.about && !darknav
                    ? "ydy-nav-item-white"
                    : ""
                }
              >
                About Us
              </span>
            </Link> */}
            <Link to="/products" className="ydy-nav-item-link">
              <span
                className={
                  props.products && darknav
                    ? "ydy-nav-item ydynavbar-items-list ydy-nav-selected"
                    : props.products && !darknav
                    ? "ydy-nav-item ydynavbar-items-list-purple ydy-nav-selected-purple"
                    : !props.products && darknav
                    ? "ydy-nav-item-purple"
                    : !props.products && !darknav
                    ? "ydy-nav-item-white"
                    : ""
                }
              >
                Products
              </span>
            </Link>
            <Link to="/games" className="ydy-nav-item-link">
              <span
                className={
                  props.games && darknav
                    ? "ydy-nav-item ydynavbar-items-list ydy-nav-selected"
                    : props.games && !darknav
                    ? "ydy-nav-item ydynavbar-items-list-purple ydy-nav-selected-purple"
                    : !props.games && darknav
                    ? "ydy-nav-item-purple"
                    : !props.games && !darknav
                    ? "ydy-nav-item-white"
                    : ""
                }
              >
                Yudimy Games
              </span>
            </Link>
            <Link to="/contact" className="ydy-nav-item-link">
              <span
                  className={
                    props.contact && darknav
                      ? "ydy-nav-item ydynavbar-items-list ydy-nav-selected"
                      : props.contact && !darknav
                      ? "ydy-nav-item ydynavbar-items-list-purple ydy-nav-selected-purple"
                      : !props.contact && darknav
                      ? "ydy-nav-item-purple"
                      : !props.contact && !darknav
                      ? "ydy-nav-item-white"
                      : ""
                  }
              >
                Contact Us
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default YudimyNavBar;
