import * as React from "react";
import "./products.css";
import precision from "../../assets/precision.svg";
import talent from "../../assets/c4t.png";
import money from "../../assets/Wallet.png";
import time from "../../assets/Time.png";
import precision2 from "../../assets/precision_logo.png";
import arrow from "../../assets/left_arrow.png";
import AOS from "aos";
import "aos/dist/aos.css";


const Precision = () => {

  React.useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1500,
      offset: 300,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="ydyproducts-clarity">
        <div>
          <a
            className="ydyproducts-clarity-link"
            href="http://precision.yudimy.com/"
            target="_blank"
            data-aos="fade-up" data-aos-duration="3000"
          >
            visit - precision.yudimy.com
          </a>
          <h4 className="ydyproducts-clarity-ttl" data-aos="fade-up" data-aos-duration="3000">
            Precision - Get the best Talent Fit Beyond Cv’s
          </h4>
          <p className="ydyproducts-clarity-txt" data-aos="fade-up" data-aos-duration="3000">
            Finding the right talent fit is a herculean task for organisations,
            especially with human behaviour being complex and in constant
            evolution.
            <br /> With our proprietary, predictive system, we help
            organisations find the right job-talent fit so that they can hire
            with ease, speed, and at over 90% accuracy.
          </p>
          <div className="ydyproducts-clarity-list ydyproducts-precision-list">
            <div>
              <div className="ydyproducts-icon-circle">
                <img src={money} alt="icon" data-aos="fade-up" data-aos-duration="3000"/>
              </div>
              <h6 data-aos="fade-up" data-aos-duration="3000">Save Money</h6>
            </div>
            <div>
              <div className="ydyproducts-icon-circle">
                <img src={time} alt="icon" data-aos="fade-up" data-aos-duration="3000" />
              </div>
              <h6 data-aos="fade-up" data-aos-duration="3000">Cut down Recruitment Time</h6>
            </div>
            <div>
              <div className="ydyproducts-icon-circle">
                <img src={talent} alt="icon" data-aos="fade-up" data-aos-duration="3000"/>
              </div>
              <h6 data-aos="fade-up" data-aos-duration="3000">Simplified Talent Matching</h6>
              <span data-aos="fade-up" data-aos-duration="3000">& much more</span>
            </div>
          </div>
        </div>
        <img
          className="ydyproducts-precision-img"
          src={precision}
          alt="precision landing page"
          data-aos="fade-up" data-aos-duration="3000"
        />
      </div>
      <a className="disable-link-underline" href="http://precision.yudimy.com/" target="_blank">
        <div className="ydyproducts-clarity-mobile margin-bottom">
          <img src={precision2} alt="clarity logo" data-aos="fade-up" data-aos-duration="3000" />
          <p className="ydyproducts-clarity-mobile-txt1" data-aos="fade-up" data-aos-duration="3000">
            Finding the right talent fit is a herculean task for organisations,
            especially with human behaviour being complex and in constant
            evolution. With our proprietary, predictive system, we help
            organisations find the right job-talent fit so that they can hire
            with ease, speed and at over 90% accuracy.
          </p>
          <p className="ydyproducts-clarity-mobile-txt2">
            Visit Precision{" "}
            <img
              className="ydyproducts-clarity-mobile-arrow"
              src={arrow}
              alt="left arrow"
            />
          </p>
        </div>
      </a>
    </>
  );
};

export default Precision;
