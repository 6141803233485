import * as React from "react";
import "./socials.css";
import social_1 from "../../assets/social_1.png";
import social_2 from "../../assets/social_2.png";
import social_3 from "../../assets/social_3.png";
import social_4 from "../../assets/social_4.png";

const YudimySocials = () => {
  return (
    <>
      <div className="ydysocials">
        <p>We are on social media 24/7 - Get in touch</p>
        <div>
          <a
            href="https://free.facebook.com/pages/category/Product-Service/109680753747119/?_rdc=1&_rdr"
            target="_blank"
          >
            <div className="ydysocials-icon">
              <img src={social_1} alt="facebook icon" />
            </div>
          </a>
          <a href="https://twitter.com/askyudimy?s=08" target="_blank">
            <div className="ydysocials-icon">
              <img src={social_2} alt="twitter icon" />
            </div>
          </a>
          <a href="https://www.instagram.com/askyudimy/" target="_blank">
            <div className="ydysocials-icon">
              <img src={social_3} alt="instagram icon" />
            </div>
          </a>
          <a href="https://www.linkedin.com/company/yudimy" target="_blank">
            <div className="ydysocials-icon">
              <img src={social_4} alt="linkedin icon" />
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default YudimySocials;
