import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/footer-logo.svg";
import "./footer.css";
import axios from "axios";
import { API } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const YudimyFooter = () => {
  const [state, setState] = React.useState({
    usermail: "",
    isLoading: false,
  });

  const { usermail, isLoading } = state;

  const handleChange = (e) => {
    setState({
      ...state,
      usermail: e.target.value,
    });
  };

  const newsletterSignUp = () => {
    setState({
      ...state,
      isLoading: true,
    });
    const data = {
      email: usermail,
    };
    // console.log(data);
    axios
      .post(`${API}/yudimy-newsletter/`, data)
      .then((response) => {
        if (response.status === 200) {
          notify("Newsletter sign up successful");
          return setState({
            ...state,
            isLoading: false,
            usermail: "",
          });
        }
      })
      .catch((error) => {
        notify("Newsletter signup failed");
        setState({
          ...state,
          isLoading: false,
          usermail: "",
        });
      });
  };

  const notify = (message: string) => {
    toast(message, { containerId: "B" });
  };
  return (
    <>
      <div className="footer-section">
        <Container className="footer" fluid={true}>
          <Row className="footer-middle">
            <Col md={5} sm={12} className="footer-starter">
              <div className="footer-logo">
                <img src={footerLogo} className="footer-img"></img>
              </div>
              <div className="footer-p">
                <p>
                  We are enabling People, families, Organizations and
                  communities to be happily productive{" "}
                </p>
              </div>

              {/* <div className="input-areas">
                <div className="footer-form">
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={usermail}
                    placeholder="Join our mailing list"
                    className="footer-input"
                  />
                  <button className="footer-btn" onClick={newsletterSignUp}>
                    {isLoading ? "Sending..." : "Subscribe"}
                  </button>
                </div>
              </div> */}
            </Col>
            <Col md={3} sm={12}>
              <div className="footer-links">
                <div className="footer-link-header">
                  <Link to="/product" className="footer-a">
                    Product
                  </Link>
                </div>
                <div className="links">
                  <ul>
                    <li>
                      <a
                        href="https://clarity.yudimy.com/"
                        target="_blank"
                        className="footer-a"
                      >
                        Clarity
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://precision.yudimy.com/"
                        target="_blank"
                        className="footer-a"
                      >
                        Precision
                      </a>
                    </li>
                    <li>
                      <Link to="/games" className="footer-a">
                        YDM Games
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            {/* <Col md={2} sm={12}>
              <div className="footer-links">
                <div className="footer-link-header">
                  <Link to="/about" className="footer-a">
                    About Us
                  </Link>
                </div>
                <div className="links">
                  <ul>
                    <li>
                      <Link to="/meettheteam" className="footer-a">
                        Meet the team
                      </Link>
                    </li>
                    <li>
                      <Link to="/ourstory" className="footer-a">
                        Our Story
                      </Link>
                    </li>
                    <li>
                      <Link to="/publications" className="footer-a">
                        Publications
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col> */}
            <Col md={4} sm={12}>
              <div className="footer-links">
                <div className="footer-link-header contact">
                  <Link to="/contact">Contact Us</Link>
                </div>
                <div className="links">
                  <p>
                    Feel free to get in touch with us via phone or send us a
                    message.
                  </p>
                  <ul>
                    <li className="footer-contact-link">
                      +234 (0) 817 610 0160
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="mailto:ask@yudimy.com"
                        className="footer-contact-link"
                      >
                        ask@yudimy.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            {/* <Col sm={12} className="footer-mobile">
              <p>
                Subscribe to our newsletter for latest updates about our
                products and exciting features
              </p>
            </Col> */}
            {/* <Col sm={12} className="footer-mobile-form">
              <div>
                <input
                  type="email"
                  name="email"
                  value={usermail}
                  placeholder="Enter your mail"
                  className="footer-input"
                  onChange={handleChange}
                />
                <button className="footer-btn" onClick={newsletterSignUp}>
                  {isLoading ? "Sending..." : "Subscribe"}
                </button>
              </div>
            </Col> */}
          </Row>

          <hr className="footer-line" />
          <div className="copyright-text">
            <p>© Yudimy 2021, All Rights Reserved</p>
          </div>
        </Container>
      </div>
      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
    </>
  );
};

export default YudimyFooter;
