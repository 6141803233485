import * as React from "react";
import "./products.css";
import clarity from "../../assets/clarity.svg";
import individuals from "../../assets/c4i.png";
import teams from "../../assets/c4t.png";
import communities from "../../assets/c4c.png";
import clarity2 from "../../assets/clarity_logo.png";
import arrow from "../../assets/left_arrow.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Clarity = () => {
  React.useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1500,
      offset: 300,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="ydyproducts-clarity">
        <div>
          <a
            className="ydyproducts-clarity-link"
            href="https://clarity.yudimy.com/"
            target="_blank"
            data-aos="fade-up" data-aos-duration="3000"
          >
            visit - clarity.yudimy.com
          </a>
          <h4 className="ydyproducts-clarity-ttl" data-aos="fade-up" data-aos-duration="3000">
            Clarity - Meaningful Work-life
          </h4>
          <p className="ydyproducts-clarity-txt" data-aos="fade-up" data-aos-duration="3000">
            CLARITY is an AI-Assisted career-matching and people analytics
            application that enables individuals and organisations to access
            career opportunities, make informed career growth and talent
            management decisions with actionable insights that facilitate a
            happily productive work-life.
          </p>
          <div className="ydyproducts-clarity-list">
            <div>
              <div className="ydyproducts-icon-circle">
                <img src={individuals} alt="icon" data-aos="fade-up" data-aos-duration="3000" />
              </div>
              <h6 data-aos="fade-up" data-aos-duration="3000">Clarity for Individuals</h6>
            </div>
            <div>
              <div className="ydyproducts-icon-circle">
                <img src={teams} alt="icon" data-aos="fade-up" data-aos-duration="3000"/>
              </div>
              <h6 data-aos="fade-up" data-aos-duration="3000">Clarity for Teams</h6>
            </div>
            <div>
              <div className="ydyproducts-icon-circle">
                <img src={communities} alt="icon" data-aos="fade-up" data-aos-duration="3000"/>
              </div>
              <h6 data-aos="fade-up" data-aos-duration="3000">Clarity for Communities</h6>
              <span data-aos="fade-up" data-aos-duration="3000">NEW</span>
            </div>
          </div>
        </div>
        <img
          className="ydyproducts-clarity-img"
          src={clarity}
          alt="clarity landing page"
          data-aos="zoom-in-down" data-aos-duration="3000"
        />
      </div>
      <a className="disable-link-underline" href="https://clarity.yudimy.com/" target="_blank">
        <div className="ydyproducts-clarity-mobile">
          <img src={clarity2} alt="clarity logo" />
          <p className="ydyproducts-clarity-mobile-txt1">
            CLARITY is an AI Assisted career matching and people analytics
            application that enables individuals and organisations make informed
            career growth and talent management decisions with actionable
            insights that facilitate a happily productive work-life.{" "}
          </p>
          <p className="ydyproducts-clarity-mobile-txt2">
            Visit Clarity{" "}
            <img
              className="ydyproducts-clarity-mobile-arrow"
              src={arrow}
              alt="left arrow"
            />
          </p>
        </div>
      </a>
    </>
  );
};

export default Clarity;
