import * as React from "react";
import "../Home/Home.css";
import Footer from "../HomeComponents/footer";
import { Col, Container, Row } from "react-bootstrap";
import firstsectionitem from "../../assets/firstsectionitem.svg";
import "./About.css";
import secondsectionline from "../../assets/secondsectionline.svg";
import sectiontwoimage from "../../assets/sectiontwoimage.svg";
import section5about from "../../assets/section5about.svg";
import thirdgroupsvg from "../../assets/thirdgroupsvg.svg";
import testimage from "../../assets/Jumoke-removebg.png";
import LinkedIn from "../../assets/LinkedIn.svg";
import bgsvg from "../../assets/BG.svg";
import blogcard from "../../assets/blogcard.png";
import { cpuUsage } from "process";
import macos from "../../assets/macos.png";
import YudimyNavBar from "../HomeComponents/navbar";
import macos1 from "../../assets/macos1.png";
import frontend2 from "../../assets/Favour_Adekogbe.jpg";
import frontend from "../../assets/Israel.png";

const YudimyAbout = () => {
  return (
    <>
      <YudimyNavBar about={true} />
      <Container className="ydyhome-container" fluid={true}>
        <Row className="section1_about">
          <Col md={6} className="topabouttext">
            <div className="headertext">About Us</div>
            <div className="introtext">
              <div className="aboutsectext1">
                Yudimy is a play of words meaning
              </div>
              <div className="aboutsectext2">
                You-Discover-Me With the underlying philosophy that by
                understanding your psychological design and that of other
                people, families and organisations will be able to make
                objective and informed decisions that optimize the quality of
                life and work performance in learning, talent acquisition,
                career, and talent management
              </div>
            </div>
          </Col>
          <Col className="textright1">
            <img
              src={firstsectionitem}
              alt=""
              className="aboutfirstsectionitem"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <img
              src={secondsectionline}
              alt="spirallines"
              className="spirallines"
            />
          </Col>
        </Row>
        <Row className="section2_about ">
          <Col md={5} className="aboutsectionthree">
            <img
              src={sectiontwoimage}
              className="sectiontwoimage img22"
              alt=""
            />
          </Col>
          <Col md={6}>
            <div className="headertext1">How it Started</div>
            <div className="introtext">
              <div className="aboutsectext1">
                In 2012, our Founder Omozino Eguh, during her National Youth
                Service at a secondary school, teaching students in SS1 spotted
                the problem of "Talent Mismatch".
              </div>
              <div className="aboutsectext2">
                Students with natural abilities and aspirations in Sciences
                found themselves studying Arts and likewise Art students pursued
                Sciences.
                <div className="aboutsectext1">
                  It turns out that the problem of talent mismatch was not only
                  restricted to the student population but also affected working
                  professionals, organizations, governance, and economies.
                </div>
                It is about time we realize that talent mismatch causes immense
                overall damage. We dare say, it is one of the primary causes for
                mental health issues faced by a large percentage of the world
                population today.
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" md={12}>
            <img src={section5about} className="spirallines" />
          </Col>
        </Row>
        <Row className="section2_about ">
          <Col md={6} className="aboutsectionwrap3">
            <div className="headertext1">
              Misplace Fits = Unproductive Community
            </div>
            <div className="introtext">
              <div className="aboutsectext2">
                <div className="aboutsectext1 aboutsectext21">
                  Just like fitting square pegs into round holes may cause
                  friction, talent mismatch is one of the overriding reasons for
                  unemployment, underemployment, attrition, poor hiring
                  outcomes, misaligned learning interventions, and parent/child
                  dichotomy.
                </div>
                This is why, at Yudimy, we do what we do. After several years of
                building expertise, dedicating time and resources to research
                and development, we established a platform that combines
                behavioural psychology and machine learning to solve the problem
                of talent mismatch.
              </div>
            </div>
          </Col>
          <Col md={5} className="aboutsectionthree">
            <img src={thirdgroupsvg} className="sectiontwoimage" alt="" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="polshift123">
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="about-traction1">
            <div className="about-traction">Traction Road Map</div>
          </Col>
          <Col md={12} className="tractionroadmap">
            <div className="aboutcardwrap">
              <div className="about_card1">
                5 years in Research and Development to create a talent matching,
                behavioural profiling and people analytics framework for the
                African Market, based on the study of over 5000 people in 5
                African Countries.
                <br />
                <div className="adjusttop">
                  Awarded by Nigeria’s 25 under 25 Most Innovative Companies to
                  watch for in Education & Human Ccapital Development.
                </div>
              </div>
              <div className="about_card2">
                Yudimy got incorporated as a corporate entity.
                <br />
                <div className="adjusttop">
                  We were awarded a $3000 grant from Covenant University Alumni
                  Eagles Nest Program for Business Innovation.
                </div>
              </div>
            </div>
            <div className="aboutcardwrap">
              <div className="about_card1">
                We converted our research into algorithms deploying a MVP that
                was adopted for career mapping, talent management and precision
                hiring by 1800 users and 5 organisations.
                <br />
                <div className="adjusttop">
                  Admitted to the Standard Chartered Bank Women In Technology
                  Acceleration Program.
                </div>
                <div className="adjusttop">
                  The Future Awards Africa Prize Nomination for Education
                </div>
              </div>
            </div>
            <div className="aboutcardwrap positionleft">
              <div className="about_card1">
                We won the Top 5 startups award with a $10,000 grant From
                Standard Chartered Bank’s Women in Technology Incubation
                Program.
                <br />
                <div className="adjusttop">
                  Transitioned Clarity for Individuals from a concierge MVP
                  platform to a full automated system.
                </div>
                <div className="adjusttop">
                  Strategic Partnership with NYSC SAEDconnect to make career
                  mapping available to Nigerian Youth Corpers.
                </div>
                <div className="adjusttop">
                  3100 people using clarity for Career mapping
                </div>
              </div>
            </div>
          </Col>
        </Row>
         <Row>
          <Col md={12} className="polshift123">
            {/* <hr /> */}
          </Col>
        </Row>
       {/* <Row className="about001">
          <Col md={12} className="about-traction1">
            <div className="about-traction">Our Awesome Team</div>
            <div className="yrrs11">
              Over the years we have built a team of awesome individuals doing
              Awesome things
            </div>
          </Col>
          <Col md={12} className="cardcontainer2">
            <div className="bgsvgwrapper">
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
            </div>
            <div className="teamcardwrap">
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={testimage} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name"> Omozino Eguh</div>
                      <span className="jobdescription">C.E.O</span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">Quiet Table Shaker</div>
                  </div>
                </div>
              </div>
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={testimage} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name">Ibukunoluwa Adebayo</div>
                      <span className="jobdescription">C.O.O</span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">
                      Resident inhouse DJ and Hypeman
                    </div>
                  </div>
                </div>
              </div>
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={testimage} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name"> Anju Kamlani</div>
                      <span className="jobdescription">Vice President</span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">In house Momla</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} className="cardcontainer2 seperator11">
            <div className="bgsvgwrapper">
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
            </div>
            <div className="teamcardwrap">
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={testimage} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name">Rotimi Akanni</div>
                      <span className="jobdescription">
                        ML Engr/Product Manager
                      </span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">Pizza Advocate</div>
                  </div>
                </div>
              </div>
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={frontend} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name">Israel Oba</div>
                      <span className="jobdescription">
                        Front End Developer
                      </span>
                    </div>
                    <div className="linkedinwrapper">
                      <a
                        href="https://www.linkedin.com/in/hilary-oba-095384118/"
                        target="blank"
                      >
                        <img src={LinkedIn} alt="" className="LinkedIn" />
                      </a>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">
                      Gentle Cruise Master{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={frontend2} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name"> Favour Adekogbe</div>
                      <span className="jobdescription">Frontend Developer</span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">Anomaly Critic</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} className="cardcontainer2 seperator11">
            <div className="bgsvgwrapper">
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
              <div className="bgsvgwrapper1">
                <img src={bgsvg} alt="contwrap" className="contwrap" />
              </div>
            </div>
            <div className="teamcardwrap">
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={testimage} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name">Olumide Sosanya</div>
                      <span className="jobdescription">Backend Engineer</span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">Shawarma King</div>
                  </div>
                </div>
              </div>
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={testimage} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name">Oluwalayomi Ehirim</div>
                      <span className="jobdescription">
                        Digital Marketing Executive
                      </span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">Cool Kid </div>
                  </div>
                </div>
              </div>
              <div className="teamcardcontainer">
                <div className="imagecontainer">
                  <img src={testimage} className="team_image_about" />
                </div>
                <div className="teamcontentinfo">
                  <div className="itemsep">
                    <div>
                      <div className="member_name">
                        {" "}
                        Phillip Olorunfemi Ofei
                      </div>
                      <span className="jobdescription">Frontend Developer</span>
                    </div>
                    <div className="linkedinwrapper">
                      <img src={LinkedIn} alt="" className="LinkedIn" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="membercharacter">Alter Ego</div>
                    <div className="membercharacter2">Puff Puff Slayer</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row> */}
        <Row className="about011">
          <Col md={12}>
            <div className="about_press_title">Press</div>
            <div className="blogwrapper">
              <div className="blogcard rel12">
                <img src={blogcard} alt="blogcard" className="blogimage" />
                <div className="blogtext">
                  Empowering young people to make smarter career choices
                  <div className="aboutus212">Read more</div>
                </div>
              </div>
              <div className="blogcard">
                <img src={blogcard} alt="blogcard" className="blogimage" />
                <div className="blogtext">
                  Meet the 5 women in tech who won 10000 at Standard Chattered
                  Incubator Program
                  <div className="aboutus212">Read more</div>
                </div>
              </div>
              <div className="blogcard rel12">
                <img src={macos} alt="blogcard" className="blogimage" />
                <div className="blogtext">
                  Nigerias 25 pressing progress
                  <div className="aboutus212">Read more</div>
                </div>
              </div>
              <div className="blogcard">
                <img src={macos1} alt="blogcard" className="blogimage" />
                <div className="blogtext">
                  SME 100 rewards 25 young Nigerians
                  <div className="aboutus212">Read more</div>
                </div>
              </div>
              <div className="blogcard rel12">
                <img src={macos} alt="blogcard" className="blogimage" />
                <div className="blogtext">
                  Omozino Eguh founder yudimy reveals social innovation build
                  next generation solid human capital africa
                  <div className="aboutus212">Read more</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default YudimyAbout;
