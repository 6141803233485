import React, { Component } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import "./App.css";
import whatsapp from "./assets/whatsapp.png";
import YudimyAbout from "./Components/About/About";

import YudimyHome from "./Components/Home/Home";
import YudimyProducts from "./Components/Products/Products";
import YudimyContact from "./Components/Contact/Contact";
import Games from "./Components/YudimyGames/Games";

class App extends Component {
  render() {
    return (
      <div className="App">
        {window.location.pathname === "/" && (
          <a href="whatsapp://send?phone=+2348176100160 &text=Hello">
            <img src={whatsapp} className="whatsapp" alt="whatsapp" />
          </a>
        )}
        <div>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={YudimyHome} />
              <Route exact path="/about" component={YudimyAbout} />
              <Route exact  path="/products" component={YudimyProducts}/>
              <Route exact path="/contact" component={YudimyContact} />
              <Route exact path="/games" component={Games} />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}
export default App;
